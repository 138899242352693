<template>
  <b-card no-body title="Facilities">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="onLoadAllFacilities">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table
        responsive
        :fields="fields"
        :items="getAllFacilities"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        sort-icon-left
        no-local-sorting
      >
        <template #cell(name)="data">
          <b-link v-model="data.detailsShowing" @click="data.toggleDetails">
            {{ data.value }}
          </b-link>
          <br /><small>DBA: {{ data.item.listoDba }}</small>
        </template>
        <template #cell(hasLogo)="data">
          <feather-icon icon="CheckCircleIcon" class="text-success mr-1" v-if="data.item.logoUrl" />
          <feather-icon icon="XCircleIcon" class="text-danger mr-1" v-if="!data.item.logoUrl" />
        </template>
        <template #cell(mediaMetadata)="data">
          {{ data.value ? data.value.length : "" }}
        </template>
        <template #cell(created)="data">
          {{ showDateInFormat(data.value) }}
        </template>
        <!-- full detail on click -->
        <template #row-details="row">
          <b-card no-body>
            <b-card-title> {{ row.item.bioBody }} </b-card-title>
            <b-row class="mb-2">
              <b-col md="8" class="mb-1">
                <span v-for="item in row.item.tags" :key="item">
                  <b-badge variant="light-primary">{{ item }}</b-badge> &nbsp;
                </span>
              </b-col>
            </b-row>
            <br />
            <b-row no-gutters>
              <b-col md="6">
                <b-card>
                  <b-carousel id="carousel-1" :interval="0" controls indicators>
                    <b-carousel-slide v-for="m of row.item.mediaMetadata" :img-src="m.smallUrl || m.url" :key="m.mediaId">
                      <h2>
                        <span class="text-capitalize text-light font-weight-bolder">{{ m.mediaType }}</span>
                        <span v-if="!m.active" class="text-danger font-weight-bolder"> (Inactive)</span>
                      </h2>
                    </b-carousel-slide>
                  </b-carousel>
                </b-card>
              </b-col>
              <b-col md="6">
                <b-card no-body>
                  <b-tabs active-nav-item-class="font-weight-bold text-info" card>
                    <!-- facility details -->
                    <b-tab title="Facility Details" key="facDetails">
                      <b-card-title> {{ row.item.name }} </b-card-title>
                      <b-card-text> <b>DBA:</b> {{ row.item.listoDba }} </b-card-text>
                      <b-card-text> <b>Headline:</b> {{ row.item.bioHeadline }} </b-card-text>
                      <b-card-text> <b>Bio:</b> {{ row.item.bioBody }} </b-card-text>
                      <br />
                      <b-card-text> <b>License:</b> {{ row.item.licenseNumber }} </b-card-text>
                      <b-card-text> <b>Started:</b> {{ row.item.licenseStartDate }} </b-card-text>
                      <b-card-text> <b>Expires:</b> {{ row.item.licenseEndDate }} </b-card-text>
                      <b-card-text> <b>UID:</b> {{ row.item.facilityUid }} </b-card-text>
                    </b-tab>
                    <!-- Buyer details -->
                    <b-tab title="As Buyer" key="asBuyer" v-if="row.item.listoAttributes && row.item.listoAttributes.isBuyer">
                      <b-card-text>
                        <b>Values:</b>
                        <b-row class="mb-2">
                          <b-col md="8" class="mb-1">
                            <span v-for="item in row.item.listoAttributes.buyerAttributes.sourceValuePreferences" :key="item">
                              <b-badge variant="light-info">{{ item }}</b-badge> &nbsp;
                            </span>
                          </b-col>
                        </b-row>
                      </b-card-text>

                      <b-card-text>
                        <b>Prefered Categories for sourcing:</b>
                        <b-row class="mb-2">
                          <b-col md="8" class="mb-1">
                            <span v-for="item in row.item.listoAttributes.buyerAttributes.sourceFromCategories" :key="item">
                              <b-badge variant="light-info">{{ item }}</b-badge> &nbsp;
                            </span>
                          </b-col>
                        </b-row>
                      </b-card-text>

                      <b-card-text>
                        <b>Customer Types:</b>
                        <b-row class="mb-2">
                          <b-col md="8" class="mb-1">
                            <span v-for="item in row.item.listoAttributes.buyerAttributes.customerTypes" :key="item">
                              <b-badge variant="light-info">{{ item }}</b-badge> &nbsp;
                            </span>
                          </b-col>
                        </b-row>
                      </b-card-text>
                      <br />
                      <b-card-text>
                        <b>Compassion? </b>
                        <template v-if="row.item.listoAttributes.buyerAttributes.compassionProgram">
                          <feather-icon icon="CheckIcon" size="18" class="text-success mr-1" />
                        </template>
                        <template v-else>
                          <feather-icon icon="XOctagonIcon" size="18" class="text-danger mr-1" />
                        </template>
                      </b-card-text>
                    </b-tab>
                    <!-- seller details -->
                    <b-tab title="As Seller" key="asBuyer" v-if="row.item.listoAttributes && row.item.listoAttributes.isSeller">
                      <b-card-text>
                        <b>Values:</b>
                        <b-row class="mb-2">
                          <b-col md="8" class="mb-1">
                            <span v-for="item in row.item.listoAttributes.sellerAttributes.sourceValuePreferences" :key="item">
                              <b-badge variant="light-info">{{ item }}</b-badge> &nbsp;
                            </span>
                          </b-col>
                        </b-row>
                      </b-card-text>

                      <b-card-text>
                        <b>Target customer:</b>
                        <b-row class="mb-2">
                          <b-col md="8" class="mb-1">
                            <span v-for="item in row.item.listoAttributes.sellerAttributes.targetCustomer" :key="item">
                              <b-badge variant="light-info">{{ item }}</b-badge> &nbsp;
                            </span>
                          </b-col>
                        </b-row>
                      </b-card-text>

                      <b-card-text> <b>Customer Likes:</b> {{ row.item.listoAttributes.sellerAttributes.customerLikes }} </b-card-text>
                      <b-card-text> <b>Our biggest strength:</b> {{ row.item.listoAttributes.sellerAttributes.biggestStrength }} </b-card-text>

                      <br />
                      <b-card-text>
                        <b>Compassion? </b>
                        <template v-if="row.item.listoAttributes.sellerAttributes.compassionProgram">
                          <feather-icon icon="CheckCircleIcon" size="18" class="text-success mr-1" />
                        </template>
                        <template v-else>
                          <feather-icon icon="XOctagonIcon" size="18" class="text-danger mr-1" />
                        </template>
                      </b-card-text>
                      <b-card-text>
                        <b>Sample program? </b>
                        <template v-if="row.item.listoAttributes.sellerAttributes.sampleProgramOptIn">
                          <feather-icon icon="CheckCircleIcon" size="18" class="text-success mr-1" />
                        </template>
                        <template v-else>
                          <feather-icon icon="XOctagonIcon" size="18" class="text-danger mr-1" />
                        </template>
                      </b-card-text>
                    </b-tab>
                    <!-- linked customers -->
                    <b-tab title="Linked Users" key="linked" @click="fetchLinkedUsers(row.item.facilityUid)">
                      <b-overlay :show="linkedUserLoading" rounded="sm" opacity="0.8">
                        <b-table responsive :fields="userFields" :items="linkedUsers" :key="updated"> </b-table>
                      </b-overlay>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>
        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  getAllFacilities,
  filterBy,
  setPerPage,
  getLoading,
  onLoadAllFacilities,
  getTotalRows,
  getPerPage,
  updateMediaItem,
  sortBy,
  sortOrder,
  loadLinkedUsersFor,
  linkedUserLoading
} from "@/views/facilities/facilityRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { toMoneyUS } from "@/service/NumberService";

import { onMounted, ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "AllFacilities",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const currentPage = ref(1);
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const fields = [
      // 'mark_resolved',
      {
        key: "name",
        label: "Name",
        sortable: true
      },
      {
        key: "licenseNumber",
        label: "License Number",
        sortable: true
      },
      {
        key: "licenseEndDate",
        label: "License Expires",
        sortable: true
      },
      {
        key: "hasLogo",
        label: "Logo?",
        thClass: "text-center",
        tdClass: "text-center"
      },
      {
        key: "mediaMetadata",
        label: "# Images"
      },
      {
        key: "userCount",
        label: "# Users"
      }
    ];

    const isSortDesc = ref(false);
    const userFields = [
      {
        key: "fullName",
        label: "Name",
        sortable: true
      },
      {
        key: "active",
        label: "Active",
        sortable: true,
        formatter: (value) => {
          return value ? "Yes" : "No";
        }
      },
      {
        key: "lastLogin",
        label: "Last Login",
        sortable: true,
        formatter: (value) => {
          if (!value) {
            return "";
          }
          return moment(value).format("YYYY-MM-DD hh:mm:ss a");
        }
      },
      {
        key: "eventAction",
        label: "Last Action",
        sortable: true
      }
    ];
    watch(filterBy, (newValue, oldValue) => {
      initalLoadFacilities();
    });

    watch(sortBy, (newValue, oldValue) => {
      initalLoadFacilities();
    });

    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      initalLoadFacilities();
    });

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const showAsMoney = (amt) => toMoneyUS(amt);

    const initalLoadFacilities = async () => onLoadAllFacilities({ prevPage: false, nextPage: false, reset: true });

    const linkedUsers = ref([]);
    const updated = ref(0);

    const fetchLinkedUsers = async (facUid) => {
      console.log("loading users for ", facUid);
      const ans = await loadLinkedUsersFor(facUid);

      linkedUsers.value = ans;
      console.log("linked users are", linkedUsers.value);

      updated.value = updated.value + 1;
    };

    const toggleSync = async (newVal, item, type) => {
      let req = {};
      req.mediaId = item.mediaId;
      switch (type) {
        case "active":
          req.updateType = "ACTIVE";
          req.updateBoolean = newVal;
          break;
        case "moderated":
          req.updateType = "MODERATED";
          req.updateBoolean = newVal;
          break;
      }
      try {
        await updateMediaItem(req);
      } catch (err) {
        let respMsg = "";
        if (err.response) {
          respMsg = err.response.data && err.response.data.message ? err.response.data.message : err.response.data.error ? err.response.data.error : "Server error";
        }
        respMsg = respMsg === "" ? "Unknown Error" : respMsg;
        root.$bvToast.toast(`Message: ${respMsg}`, {
          title: `Updating Facility media for ${item.name} Failed`,
          toaster: "b-toaster-bottom-center",
          variant: "danger",
          autoHideDelay: 5000
        });
        await initalLoadFacilities();
      }
    };

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadFacilities();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllFacilities({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllFacilities({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllFacilities({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(() => {
      filterBy.value = "";
    });

    return {
      getAllFacilities,
      filterBy,
      setPerPage,
      getLoading,
      onLoadAllFacilities,
      getTotalRows,
      getPerPage,
      onPageChanged,
      perPageChanged,
      showDateInFormat,
      currentPage,
      pageOptions,
      fields,
      showAsMoney,
      toggleSync,
      isSortDesc,
      sortBy,
      fetchLinkedUsers,
      linkedUsers,
      userFields,
      updated,
      linkedUserLoading
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
