import axiosIns from "@/libs/axios";

export function requestLoadFacilities(reqData) {
  return axiosIns.post("/admin/list_facilities", reqData, {});
}

export function requestUpdateMediaItem(reqData) {
  return axiosIns.post("/admin/update_mediaItem", reqData, {});
}

export function requestLoadLinkedUsersFor(reqData) {
  return axiosIns.post("/admin/linked_users", reqData, {});
}
