import { ref, computed } from "@vue/composition-api";

import { requestLoadFacilities, requestUpdateMediaItem, requestLoadLinkedUsersFor } from "@/service/FacilityService";

const allFacilities = ref([]);
export const filterBy = ref(null);
const perPage = ref(10);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);
export const linkedUserLoading = ref(false);

export const sortBy = ref("license_expires");
export const sortOrder = ref("asc");

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getAllFacilities = computed(() => allFacilities.value);

export const updateMediaItem = async (data) => {
  try {
    loading.value = true;
    await requestUpdateMediaItem(data);
  } finally {
    loading.value = false;
  }
};

function getSort() {
  switch (sortBy.value) {
    case "licenseNumber":
      return "license_number";
    case "licenseEndDate":
      return "license_expires";
    case "name":
      return "name";
    default:
      return sortBy.value;
  }
}

export const loadLinkedUsersFor = async (facilityUid) => {
  const reqData = {
    forFacilityUid: facilityUid
  };
  try {
    linkedUserLoading.value = true;
    const {
      data: { linkedUsers }
    } = await requestLoadLinkedUsersFor(reqData);
    return linkedUsers;
  } finally {
    linkedUserLoading.value = false;
  }
};

export const onLoadAllFacilities = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    sort: getSort(),
    sortOrder: sortOrder.value,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { facilities, pagination }
    } = await requestLoadFacilities(reqData);

    if (facilities) {
      allFacilities.value.splice(0, allFacilities.value.length);
      allFacilities.value.push(...facilities);
    } else {
      allFacilities.value.splice(0, allFacilities.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};
